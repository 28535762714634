.MindContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 100%;
}

.MindContentClass .MuiGrid-item:last-child {
    align-items: flex-start;
}

.MindContentClass .triggerTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    width: 80%;
}

.MindContentClass .triggerContent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #B9BFCA;
    margin-top: 8px;
    width: 80%;
}

.MindContentClass .triggerCard {
    width: 80%;
    background: unset;
    border: 1px solid #444681;
    border-radius: 16px;
    box-sizing: border-box;

    display: flex;
    align-items: flex-start;
    padding: 6px;
    margin-top: 10px;
}

.MindContentClass .triggerCard .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.MindContentClass .triggerCard .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.MindContentClass .triggerCard .title,
.MindContentClass .triggerCard .content {
    text-align: left;
    position: relative;
}

.MindContentClass .MuiCardContent-root {
    width: 100%;
}

.MindContentClass .triggerCard .MuiCardContent-root:last-child {
    padding-bottom: 16px;
}

.MindContentClass .comma {
    position: absolute;
    left: 7%;
    margin-top: -9%;
    max-width: 90%;
}

.MindContentClass .trigger1 {
    max-width: 100%;
    width: 100%;
    opacity: 0.8;
}

.MindContentClass .quoteTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    color: #FFFFFF;    
    margin-left: 12%;
    margin-bottom: 6%;
}

.MindContentClass .attentionReaction span:last-child {
    float: right;
}

@media screen and (max-width: 900px) {
    .MindContentClass .comma {
        position: absolute;
        left: 5%;
        top: 0px;
        margin-top: 0;
        width: 15%;
    }

    .MindContentClass .MuiGrid-item:first-child {
        padding-bottom: 50px;
    }
}