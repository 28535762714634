.TravelClass {
    margin-top: 50px;
    padding-bottom: 100px;
}

.TravelClass .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 80px;
    margin-left: 25%;
    width: 50%;
    color: #F8CE7A;
    margin-top: -45%;
    text-shadow: 0px 4px 52px rgba(248, 206, 122, 0.3);
}

.TravelClass .travel {
    max-width: 90%;
    margin-top: -20%;
}

@media screen and (max-width: 900px) {
}