.CurrentContentClass {
    padding-top: 100px;
}

.CurrentContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.CurrentContentClass .questionTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.CurrentContentClass .current1 {
    padding-top: 50px;
    padding-bottom: 10px;
    width: 90%;
}

.CurrentContentClass .card {
    background: none;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 6px;

    border: 1px solid #444681;
    border-radius: 16px;
    width: 90%;
    margin-top: 10px;
}

.CurrentContentClass .card p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.CurrentContentClass .card .bigTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    margin-top: 8px;
    margin-bottom: 8px;
}

.CurrentContentClass .card .smallTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    margin-top: 8px;
    margin-bottom: 8px;
}

.CurrentContentClass .card .MuiCardContent-root:last-child {
    padding: 6px;
}

@media screen and (max-width: 900px) {
    .CurrentContentClass .question {
        padding-top: 20px;
    }
}