.FollowJourneyClass {
    margin-top: -80px;
}

.FollowJourneyClass .science {
    width: 30%;
}

.FollowJourneyClass .name {
    font-family: 'Gordita';
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    font-style: normal;
    margin-top: -12%;
}

.FollowJourneyClass .line {
    padding-top: 20px;
}

@media screen and (max-width: 900px) {
    .FollowJourneyClass {
        margin-top: 0px;
    }
}