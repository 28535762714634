.DrivingContentClass {
    padding-top: 100px;
}

.DrivingContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.DrivingContentClass .questionTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.DrivingContentClass .questionTitle1 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.DrivingContentClass .current1 {
    padding-top: 50px;
    padding-bottom: 10px;
    width: 90%;
}

.DrivingContentClass .card {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 6px;

    background: #09162D;
    border: 1px solid #444681;
    border-radius: 16px;
    width: 90%;
    margin-top: 10px;
}

.DrivingContentClass .card .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.DrivingContentClass .card .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.DrivingContentClass .card .MuiCardContent-root:last-child {
    padding: 6px;
}

.DrivingContentClass .power1,
.DrivingContentClass .power2,
.DrivingContentClass .power3 {
    margin-top: -100px;
}

@media screen and (max-width: 900px) {
    .DrivingContentClass .question {
        padding-top: 20px;
    }
}