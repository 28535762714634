.ExperimentClass .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;

    color: #FFFFFF;
}

.ExperimentClass .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #FFFFFF;

    opacity: 0.5;
}

.ExperimentClass .line {
    margin-top: 20px;
}