.headerClass .MuiGrid-container {
    position: relative;
    aspect-ratio: 1 / 0.4;
}

.headerClass .MuiGrid-item:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.headerClass .MuiGrid-item:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerClass .left_mountain {
    width: 66%;
    max-height: 80%;
}

.headerClass .title {
    font-family: 'Gordita' !important;
    font-style: normal !important;
    font-size: 32px !important;
    font-weight: 900 !important;
    color: #FFFFFF;
    line-height: 40px !important;
}

.headerClass .time {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #FFFFFF;
    line-height: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.headerClass .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.75;
    width: 70%;
}

.headerClass .logo {
    width: 25%;
}

.headerClass .clock {
    padding-right: 5px;
}

@media screen and (max-width: 900px) {
    .headerClass .MuiGrid-item:first-child {
        align-items: center;
    }
    .headerClass .left_mountain {
        max-width: 90%;
    }
    .headerClass .MuiGrid-item:first-child {
        text-align: center;
    }
    .headerClass .logo {
        padding-top: 20px;
    }
    .headerClass .content {
        width: 80%;
    }
}