.StoreContentClass {
    margin-top: -25%;
}

.StoreContentClass .containerBox {
    margin-bottom: 20px;
}

.StoreContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.StoreContentClass .card {
    background: none;
    border: 1px solid #F8CE7A;
    box-shadow: 0px 4px 76px rgba(248, 206, 122, 0.4);
    border-radius: 32px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.StoreContentClass .card .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
}

.StoreContentClass .card .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-top: 10px;
    opacity: 0.75;
}

.StoreContentClass .card .MuiCardContent-root {
    width: 60%;
    padding-top: 120px;
    padding-bottom: 100px;
}

.StoreContentClass .card .stack {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.StoreContentClass .card .stack button {
    width: 30%;
    border: unset;
}

.StoreContentClass .card .stack button img {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .StoreContentClass .card .MuiCardContent-root {
        width: 80%;
    }
}