.LandingContentClass {
    padding-top: 50px;
    position: relative;
    z-index: 1;
}

.LandingContentClass .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%;
}

.LandingContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.LandingContentClass .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: 90%;
    background: rgba(255, 255, 255, 0.04);
    position: relative;
}

.LandingContentClass .card .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.LandingContentClass .card .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

    opacity: 0.75;
}

.LandingContentClass .card .stack {
    justify-content: center;
    align-items: center;
}

.LandingContentClass .card .stack .box {
    padding-right: 10%;
}

.LandingContentClass .apexcharts-svg foreignObject {
    display: none;
}

.LandingContentClass .card .cardBox {
    border: 1px solid #1D3760;
    text-align: left;
    padding: 10px;
}

.LandingContentClass .card .cardBox .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F8CE7A;
}

.LandingContentClass .card .cardBox .text span {
    margin-right: 10px;
}

.LandingContentClass .card .cardBox .text .circle {
    height: 8px; 
    width: 8px; 
    left: 0px; 
    top: 0px; 
    border-width: 0px; 
    border-color: rgb(255, 255, 255); 
    border-radius: 16px;
    display: inline-block;
    margin-right: 6px;
}

@media screen and (max-width: 900px) {
    .LandingContentClass .container {
        padding-left: 2%;
        padding-right: 2%;
    }
}