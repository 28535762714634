.ArriveContentClass {
    margin-top: -35%;
}

.ArriveContentClass .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ArriveContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.ArriveContentClass .stack {
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ArriveContentClass .stack .science {
    max-width: 100%;
    width: 100%;
}

.ArriveContentClass .stack .box {
    position: absolute;
    left: 55%;
    text-align: left;
}

.ArriveContentClass .stack .box .bigTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    color: #FFFFFF;
    margin-top: 4px;
    margin-bottom: 4px;
}

.ArriveContentClass .stack .box .smallTitle {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.ArriveContentClass .stack .box .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.ArriveContentClass .apexcharts-legend,
.ArriveContentClass .apexcharts-toolbar {
    display: none;
}

@media screen and (max-width: 900px) {
    .ArriveContentClass .container {
        padding-left: 2%;
        padding-right: 2%;
    }
}