.FooterClass {
    margin-top: 50px;
    padding-bottom: 100px;
}

.FooterClass .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.FooterClass .logo {
    width: 6%;
}

.FooterClass .stack {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

@media screen and (max-width: 900px) {
    .FooterClass .logo {
        width: 20%;
    }
}