.CommonClass {
    margin-top: 100px;
}

.CommonClass .container {
    width: 90%;
    display: flex;
    justify-content: center;
}

.CommonClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.CommonClass .MuiGrid-item img {
    max-width: 90%;
}

.CommonClass .MuiGrid-item nav {
    border-top: solid 1px grey;
    width: 100%;
}

.CommonClass .MuiGrid-item:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    max-width: 100%;
    padding-left: 10%;
}

.CommonClass .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.CommonClass .listTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.CommonClass .listContent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

    opacity: 0.75;
}

.CommonClass .MuiListItemButton-root {
    padding-left: 0;
}

.CommonClass svg {
    color: #FFFFFF;
}

@media screen and (max-width: 900px) {
    .CommonClass .MuiGrid-item:last-child {
        margin-top: 20px;
    }
}