.MethodContentClass {
    padding-top: 50px;
}

.MethodContentClass .containerBox {
    margin-bottom: 20px;
}

.MethodContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.MethodContentClass .activity_title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    color: #FFFFFF;
    margin-top: 20px;
    text-shadow: 0px 0px 32px rgba(32, 239, 164, 0.58);
}

.MethodContentClass .card1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: 90%;
    background: #0D0D0D;
    height: 100%;
    position: relative;
}

.MethodContentClass .card1 .method1,
.MethodContentClass .card1 .method2,
.MethodContentClass .card1 .method3 {
    width: 100%;
}

.MethodContentClass .card1 .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.MethodContentClass .card1 .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

    opacity: 0.75;
}

.MethodContentClass .card1 .card1Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    margin-left: 20px;
    margin-right: 20px;
    gap: 4px;
    margin-top: 10px;
    border: 1px solid #555555;
    border-radius: 4px;
}

.MethodContentClass .card1 .card1Box .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.MethodContentClass .card1 .card1Box p {
    display: flex;
    align-items: flex-start;
}

.MethodContentClass .card1 .card1Box img {
    padding-right: 5px;
}

.MethodContentClass .card2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 16px;
    width: 85%;
    background: rgba(255, 255, 255, 0.04);
}

.MethodContentClass .card2 .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.MethodContentClass .card2 .subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

    opacity: 0.75;

}

.MethodContentClass .card2 .MuiCardContent-root {
    width: 70%;
    padding: unset;
}

.MethodContentClass .card2 .card2Box {
    margin-top: 10px;
    position: relative;
}

.MethodContentClass .card2 .card2Box .apexcharts-datalabel-label {
    font-family: 'Gordita' !important;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.MethodContentClass .card2 .card2Box .apexcharts-datalabel-value {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.MethodContentClass .apexcharts-legend-series {
    text-align: left;
}

.MethodContentClass .journeyItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.MethodContentClass .journeyItem .bigTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 10px;
}

.MethodContentClass .journeyItem .loadstack {
    display: flex;
    align-items: center;
}

.MethodContentClass .journeyItem .load {
    width: 10%;
    height:80%;
    margin-right: 20px;
}

.MethodContentClass .journeyItem .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.MethodContentClass .journeyItem .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;

    opacity: 0.75;
}

.MethodContentClass .card3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: #09162D;
    border: 1px solid #7073C0;
    box-shadow: 0px 0px 32px rgba(32, 239, 164, 0.58);
    border-radius: 8px;
    width: 90%;
    margin-bottom: 10px;
    position: relative;
}

.MethodContentClass .card3 .MuiCardContent-root {
    width: 70%;
    padding: unset;
}

.MethodContentClass .card3 button {
    border: 1px solid #FFFFFF;
    border-radius: 36px;
    color: #FFFFFF;
}

.MethodContentClass .card3 .rightArrow {
    width: 3%;
    height: 10%;
    position: absolute;
    right: 5%;
}

.MethodContentClass .card3 .stack {
    display: flex;
    align-items: center;
}

.MethodContentClass .card3 .stack1 {
    flex-direction: unset;
    padding: 10px 0 10px 65px;
}

.MethodContentClass .card3 .stack2 {
    align-items: center;
    margin-top: 10px;
}

.MethodContentClass .card3 .stack2 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #20EFA4;
    margin-left: 10px;
}

.MethodContentClass .apexcharts-menu-icon {
    display: none;
}

.MethodContentClass .apexcharts-datalabels-group {
    display: block;
}

.MethodContentClass .card2 .chartTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.MethodContentClass .card2 .chartContent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.MethodContentClass .card2 .card2Chart {
    position: absolute;
    top: 30%;
    left: 25%;
}

.MethodContentClass .card2 .legend-marker {
    background: #21DF9B;
    color: #21DF9B; 
    height: 12px; 
    width: 12px; 
    left: 0px; 
    top: 0px; 
    border-width: 0px; 
    border-color: rgb(255, 255, 255); 
    border-radius: 12px;
    display: inline-block;
    margin-right: 10px;
}

.MethodContentClass .card2 .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.MethodContentClass .card2 .card2Subbox {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    position: absolute;
    right: -50px;
    top: 0;
}

.MethodContentClass .card2 .chartjs-render-monitor {
    margin-left: -60px;
}

.MethodContentClass .apexcharts-svg foreignObject {
    display: none;
}

.MethodContentClass .card1 .card1Box1 {
    border: 1px solid #1D3760;
    text-align: left;
    padding: 10px;
}

.MethodContentClass .card1 .card1Box1 .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #20EFA4;
}

.MethodContentClass .card1 .card1Box1 .text span {
    margin-right: 10px;
}

.MethodContentClass .card1 .card1Box1 .text .circle {
    height: 8px; 
    width: 8px; 
    left: 0px; 
    top: 0px; 
    border-width: 0px; 
    border-color: rgb(255, 255, 255); 
    border-radius: 16px;
    display: inline-block;
    margin-right: 6px;
}

@media screen and (max-width: 900px) {

}