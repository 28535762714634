.AimContentClass {
    padding-top: 100px;
}

.AimContentClass .card {
    background: #171717;
    border: 1px solid #393939;
    border-radius: 16px;
    width: 60%;
    margin-left: 20%;
    padding: 30px 10px 30px 10px;
    height: 500px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AimContentClass .card .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.AimContentClass .card .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.75;
}

.AimContentClass .card .smallTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-top: -30px;
}

.AimContentClass .card .cardBox {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.AimContentClass .card .cardBox .cardBoxGrid {
    margin-right: 20px;
}

.AimContentClass .card img {
    border-radius: 20px;
}

.AimContentClass .aim {
    padding-top: 20px;
    max-width: 80%;
    width: 30%;
}

.AimContentClass .card .smallTitle1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.AimContentClass .card .smallTitle2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #67748A;
}

.AimContentClass .next,
.AimContentClass .previous {
    cursor: pointer;
    margin-top: 20px;
    background: #5200FF;
    border-radius: 56px;
    height: 66px;
}

.AimContentClass .previous {
    margin-right: 20px;
}

.AimContentClass .unactive {
    opacity: 0.5;
}

.AimContentClass .card .firstTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.AimContentClass .card .firstContent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 30px;
    opacity: 0.75;
}

.AimContentClass .card .firstIconTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.AimContentClass .card .secondBox1 {
    float: left;
    padding-left: 10%;
}

.AimContentClass .card .secondBox2 {
    float: right;
    padding-right: 10%;
}

.AimContentClass .card .secondDiv {
    text-align: left;
    border: 1px solid #444681;
    border-radius: 16px;
    padding: 15px;
}

.AimContentClass .card .secondHeader {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
}

.AimContentClass .card .secondTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.AimContentClass .card .secondContent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.AimContentClass .card .secondBlueTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-decoration-line: underline;
    color: #20EFA4;
}

.AimContentClass .card .firstContentBox {
    width: 100%;
    position: relative;
}

.AimContentClass .card .firstcardbox {
    position: absolute;
    left: -150px;
    opacity: 0.4;
}

.AimContentClass .card .lastcardbox {
    position: absolute;
    right: -160px;
    top: 0;
    opacity: 0.4;
}

@media screen and (max-width: 900px) {
    .AimContentClass .card {
        width: 80%;
        margin-left: 10%;
    }
}