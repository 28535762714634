.SubOptionTitleClass .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;

    color: #FFFFFF;
}

.SubOptionTitleClass .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.5;
    margin-top: 16px;
}

.SubOptionTitleClass .itemTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 40px;
}

.SubOptionTitleClass .buttonGroup {
    padding-top: 10px;
    max-width: 100%;
}

.SubOptionTitleClass .buttonGroup img {
    margin-right: 10px;
    box-shadow: 5px 5px 40px 5px rgba(123, 97, 255, 0.6);
    border-radius: 56px;
    cursor: pointer;
}

.dialog .MuiDialog-paper {
    padding: 20px;
}

.SubOptionTitleClass .dialogText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #161618;
}