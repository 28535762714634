.VersionContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 100%;
}

.VersionContentClass .MuiCardContent-root {
    width: 100%;
}

.VersionContentClass .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.VersionContentClass .card {
    width: 70%;
    background: unset;
    border: 1px solid #444681;
    border-radius: 16px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 6px 16px 6px 16px;
    margin-top: 10px;
}

.VersionContentClass .card .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.VersionContentClass .card .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-top: 8px;
    margin-bottom: 8px;
}

.VersionContentClass .version1,
.VersionContentClass .version2,
.VersionContentClass .version3 {
    max-width: 90%;
    padding: 3% 0;
}

@media screen and (max-width: 900px) {
    .VersionContentClass .MuiGrid-item:last-child {
        padding-top: 1%;
    }

    .VersionContentClass .qIcon1 {
        padding-top: 20px;
    }

    .VersionContentClass .version1,
    .VersionContentClass .version2,
    .VersionContentClass .version3 {
        margin-top: 20px;
    }
}