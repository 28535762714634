.HighlightContentClass {
    padding-top: 50px;
}

.HighlightContentClass .MuiGrid-container {
    display: flex;
    justify-content: center;
}

.HighlightContentClass .containerBox {
    margin-bottom: 20px;
}

.HighlightContentClass .MuiGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.HighlightContentClass .activity_title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    color: #FFFFFF;
    margin-top: 20px;
    text-shadow: 0px 0px 32px rgba(32, 239, 164, 0.58);
}

.HighlightContentClass .journeyItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.HighlightContentClass .journeyItem .bigTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 10px;
}

.HighlightContentClass .journeyItem .loadstack {
    display: flex;
    align-items: center;
}

.HighlightContentClass .journeyItem .load {
    width: 10%;
    height:80%;
    margin-right: 20px;
}

.HighlightContentClass .journeyItem .title {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.HighlightContentClass .journeyItem .content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;

    opacity: 0.75;
}

.HighlightContentClass .card3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: #09162D;
    border: 1px solid #7073C0;
    box-shadow: 0px 0px 32px rgba(32, 239, 164, 0.58);
    border-radius: 8px;
    width: 90%;
    margin-bottom: 10px;
    position: relative;
}

.HighlightContentClass .card3 .MuiCardContent-root {
    width: 70%;
    padding: unset;
}

.HighlightContentClass .card3 button {
    border: 1px solid #FFFFFF;
    border-radius: 36px;
    color: #FFFFFF;
}

.HighlightContentClass .card3 .rightArrow {
    width: 3%;
    height: 10%;
    position: absolute;
    right: 5%;
}

.HighlightContentClass .card3 .stack {
    display: flex;
    align-items: center;
}

.HighlightContentClass .card3 .stack1 {
    flex-direction: unset;
    padding: 10px 0 10px 65px;
}

.HighlightContentClass .card3 .stack2 {
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}

.HighlightContentClass .card3 .stack2 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #20EFA4;
    margin-left: 10px;
}

.highlightDialog .MuiPaper-root {
    background: #202427;
    padding: 50px;
}

.highlightDialog .MuiPaper-root {
    color: #FFFFFF;
}

.highlightDialog .MuiPaper-root button {
    color: #FFFFFF;
}

.highlightDialog .loadstack {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.highlightDialog .load {
    margin-right: 20px;
}

.highlightDialog .title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.highlightDialog .dialogBox {
    width: 660px;
    height: 360px;
    background: #67748A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.highlightDialog .dialogText {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.highlightDialog .dialogImg {
    width: 100%;
    height: 100%;
}

.highlightDialog .dialogVideo {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 900px) {
    .highlightDialog .MuiPaper-root {
        padding: 0%;
    }
}