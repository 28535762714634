@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Gordita";
  src: local("Gordita"),
   url("./fonts/Type Atelier - Gordita Black.otf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
